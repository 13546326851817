import React from "react";
import theme from "theme";
import { Theme, Text, Box, Section, Image, Button } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"services"} />
		<Helmet>
			<title>
				Temiz Hizmet
			</title>
			<meta name={"description"} content={"Özel ihtiyaçlarınıza göre uyarlanmış birinci sınıf ev temizlik hizmetleri sunarak burada devreye giriyoruz. "} />
			<meta property={"og:title"} content={"Temiz Hizmet"} />
			<meta property={"og:description"} content={"Özel ihtiyaçlarınıza göre uyarlanmış birinci sınıf ev temizlik hizmetleri sunarak burada devreye giriyoruz. "} />
			<meta property={"og:image"} content={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"shortcut icon"} href={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<link rel={"apple-touch-startup-image"} href={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
			<meta name={"msapplication-TileImage"} content={"https://images.unsplash.com/photo-1628177142898-93e36e4e3a50?ixlib=rb-4.0.3&q=85&fm=jpg&crop=entropy&cs=srgb&w=2000"} />
		</Helmet>
		<Components.Header>
			<Override slot="link" />
			<Override slot="link1" />
			<Override slot="link2" />
			<Override slot="link3" />
		</Components.Header>
		<Section padding="90px 0 100px 0" quarkly-title="Price-19">
			<Text margin="0px 0px 20px 0px" font="--headline2" text-align="center">
				Uzman temizlik çözümleri
			</Text>
			<Text
				margin="0px 0px 70px 0px"
				text-align="center"
				font="--lead"
				color="--darkL1"
				md-margin="0px 0px 50px 0px"
			>
				Temiz Hizmet Services olarak ihtiyaçlarınıza uygun çeşitli temizlik çözümleri sunuyoruz. Misyonumuz, çevre dostu ürünler ve ileri teknoloji kullanarak evinizi kusursuz ve konforlu hale getirmektir.
			</Text>
			<Box
				min-width="100px"
				min-height="100px"
				display="grid"
				grid-template-columns="repeat(3, 1fr)"
				grid-gap="0 50px"
				md-grid-template-columns="1fr"
				md-grid-gap="36px 0"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="flex-start"
				>
					<Text margin="0px 0px 20px 0px" font="--headline3">
						ev temizliği
					</Text>
					<Box min-width="100px" min-height="100px">
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 10px 0px"
							font="--base"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
								Standart temizlik: Yaşam alanlarının, mutfakların ve banyoların düzenli bakımı.
							</Text>
						</Box>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 10px 0px"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
								Konut alanları: toz alma, süpürme ve zemin temizliği.
							</Text>
						</Box>
						<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
							Mutfak: Tezgahların silinmesi, temizlik aletleri.
						</Text>
						<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
							Derinlemesine Temizlik: Kapsamlı temizlik için gizli alanlara ulaşmak.
						</Text>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							color="--darkL1"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
								Banyolar: tuvaletlerin, lavaboların, duşların yıkanması.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="flex-start"
				>
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Varışta/ayrılışta temizlik
					</Text>
					<Box min-width="100px" min-height="100px">
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 10px 0px"
							font="--base"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
								Taşınma Öncesi Temizlik: Yeni Evinizi Hazırlamak.
							</Text>
						</Box>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 10px 0px"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
								Zeminler: Halıların, fayansların derinlemesine temizliği.
							</Text>
						</Box>
						<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
							Dolaplar: içinin ve dışının silinmesi.
						</Text>
						<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
							Banyo temizliği: Kapsamlı sanitasyon.
						</Text>
						<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
							Windows: Eşiklerin, izlerin temizlenmesi.
						</Text>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							color="--darkL1"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
								Taşınma sonrası temizlik: Eski eviniz temiz kalacaktır.
							</Text>
						</Box>
					</Box>
				</Box>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="column"
					justify-content="flex-start"
				>
					<Text margin="0px 0px 20px 0px" font="--headline3">
						Mevsimsel temizlik
					</Text>
					<Box min-width="100px" min-height="100px">
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 10px 0px"
							font="--base"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
								Bahar temizliği: Kıştan sonra canlandırıcı.
							</Text>
						</Box>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							margin="0px 0px 10px 0px"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
								Dış alan: iç bahçelerin temizlenmesi.
							</Text>
						</Box>
						<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
							İç mekanın detaylandırılması: Odaların derinlemesine temizliği.
						</Text>
						<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
							Manzara: Sahneyi ayarlama.
						</Text>
						<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
							Organizasyon: alanı temizlemek.
						</Text>
						<Box
							min-width="10px"
							min-height="10px"
							display="flex"
							justify-content="space-between"
							align-items="center"
							color="--darkL1"
						>
							<Text margin="0px 10px 0px 0px" font="--base" color="--darkL1">
								Tatil temizliği: Tatile hazırlık.
							</Text>
						</Box>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 90px 0" background="#f0e5dc" quarkly-title="Product-4">
			<Override slot="SectionContent" align-items="center" />
			<Box
				min-width="100px"
				min-height="100px"
				display="block"
				grid-template-columns="repeat(3, 1fr)"
				grid-template-rows="auto"
				grid-gap="0 35px"
				md-grid-template-columns="1fr"
				md-grid-gap="40px 0"
				margin="0px 0px 0 0px"
				lg-margin="0px 0px 0 0px"
			>
				<Box
					min-width="100px"
					min-height="100px"
					display="flex"
					flex-direction="row"
					align-items="center"
					md-flex-direction="column"
				>
					<Image
						src="https://uploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08:50:53.460Z"
						display="block"
						max-width="100%"
						margin="0px 0px 0 0px"
						height="500px"
						width="50%"
						object-fit="contain"
						sm-height="220px"
						md-width="100%"
						md-margin="0px 0px 50px 0px"
						sm-margin="0px 0px 35px 0px"
						order="1"
						srcSet="https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08%3A50%3A53.460Z&quality=85&w=500 500w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08%3A50%3A53.460Z&quality=85&w=800 800w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08%3A50%3A53.460Z&quality=85&w=1080 1080w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08%3A50%3A53.460Z&quality=85&w=1600 1600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08%3A50%3A53.460Z&quality=85&w=2000 2000w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08%3A50%3A53.460Z&quality=85&w=2600 2600w,https://smartuploads.quarkly.io/665448d7e8900d0022f2aff2/images/1-3.jpg?v=2024-05-27T08%3A50%3A53.460Z&quality=85&w=3200 3200w"
						sizes="(max-width: 576px) 100vw,(max-width: 768px) 100vw,(max-width: 992px) 100vw,100vw"
					/>
					<Box
						min-width="100px"
						min-height="100px"
						padding="0px 80px 0px 80px"
						width="50%"
						lg-padding="0px 50px 0px 50px"
						md-width="100%"
						sm-padding="0px 0 0px 0"
					>
						<Text margin="0px 0px 15px 0px" font="normal 600 42px/1.2 --fontFamily-sans" text-align="left" lg-font="normal 600 36px/1.2 --fontFamily-sans">
							Özel temizlik
						</Text>
						<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
							Özel hizmetlerle benzersiz temizlik ihtiyaçlarını karşılıyoruz.


Alerjilerden temizlik: evdeki alerjenlerin azaltılması.
							<br />
							<br />
							{" "}HEPA elektrikli süpürge: toz toplama. Hava filtreleri: temizleme, filtrelerin değiştirilmesi. Yatak takımı: derin temizlik şilteleri. İnşaat sonrası temizlik: Tadilat sonrası temizlik. Enkaz giderme: toz giderme. Detaylı temizlik: çerçevelerin, bağlantı elemanlarının temizliği. Zemin bakımı: Zemin cilalama.
						</Text>
						<Button
							font="normal 500 18px/1.5 --fontFamily-sans"
							href="/"
							padding="0 0 0 0"
							background="0"
							color="--red"
						>
							İletişim &gt;&gt;
						</Button>
					</Box>
				</Box>
			</Box>
		</Section>
		<Section padding="100px 0 120px 0" sm-padding="80px 0 90px 0" quarkly-title="Statistics-10">
			<Text
				margin="0px 0px 20px 0px"
				letter-spacing="2px"
				color="#cd9f55"
				font="--headline2"
				border-color="#dca654"
			>
				Bize Ulaşın
			</Text>
			<Text margin="0px 0px 50px 0px" font="normal 300 18px/1.5 --fontFamily-sansHelvetica" lg-margin="0px 0px 40px 0px" sm-margin="0px 0px 30px 0px">
				Temiz Hizmet farkını ve tüm teklif çeşitlerini deneyimleyin. Olağanüstü hizmet sunmaya çalışıyoruz. Bir soruşturma için veya bir hizmet planlamak için bizimle iletişime geçin. Siz tertemiz bir evin tadını çıkarırken temizliği biz yapalım.
			</Text>
		</Section>
		<Components.Footer>
			<Override slot="link4" />
		</Components.Footer>
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65b35f058e2e8e002176cca3"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});